import styled from 'styled-components';

const RegularFont = 'Rubik';
const MediumFont = 'Rubik-Medium';

const MainContainer = styled.div`
float: left;
width: 100%;
height: 100%;
width: 100%;
padding-top: 25px;
padding-right: 25px;
padding: ${({ paddingValue }) => paddingValue ? paddingValue : '15px 25px 0px 0px'};
min-height: auto;
margin-bottom: ${({ marginValue }) => marginValue ? marginValue : '0px'};
  @media(max-width: 1200px) {
		width: 100%;
		margin-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }

`;
const SubContainer = styled.div`
width: 100%;
height: 100px;
cursor: pointer;
background-color: #FFF;
margin-top: 15px;
border-radius: 6px;
box-shadow: 0 2px 2px rgb(0 0 0 / 19%), 0 4px 6px rgb(0 0 0 / 7%);

> div: first-child {
  padding: 5px;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background ? 'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)' : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding:0px;
  border-radius: 6px 0px 0px 6px;
}

> div: last-child {
  width: 100%;
  height: 100%;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  padding:0px;
  border-radius: 6px;
}
`;

const SubContainerV2 = styled.div`
width: 100%;
height: 100%;
cursor: pointer;
background-color: #FFF;
margin-top: 15px;
border-radius: 6px;

> div: first-child {
  padding: 5px;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background ? background : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding:0px;
  border-radius: 6px 0px 0px 6px;
}

> div: last-child {
  width: 100%;
  height: 100%;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  padding:0px;
  border-radius: 6px;
}
`;

const SubContainerBehavior = styled.div`
width: 100%;
height: 100%;
cursor: pointer;

border-radius: 6px;
display: flex;
> div: first-child {
  margin-top: 15px;
  width: 36.2%;
  min-height: 215px;
  background: linear-gradient(rgb(30, 100, 129) 0%, rgb(13, 66, 92) 60.94%, rgb(2, 44, 67) 100%);
  border-radius: 6px 6px 0px 6px;
}
> div: last-child {
  margin-top: 15px;
  width: 63.8%;
  background-color: #FFF;
}
`;

const ContainerFirst = styled.div`
width: 100%;
height: 100%;
>div:nth-child(1){
width: 100%;

font-family: 'Rubik-Medium';
font-size: 16px;
line-height: 20px;
color:#FFFFFF;
padding: 12px 15px 0px 15px;

}
>div:nth-child(2){
  width: 100%;

  font-family: 'Rubik-Medium';
  font-size: 14px;
  line-height: 16px;
  color:#FFFFFF;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 35px 15px 0px 15px;
  text-align: center;
}
>div:nth-child(3){
  padding-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
>button{
  width: 80%;
  height: 50px;
  border-radius: 6px;
  border: none;
  background:linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%);

  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 21px;
  color:#FFFF;
  margin: auto;
}
}
`;

const ContainerLast = styled.div`
width: 100%;
height: 100%;
>div:nth-child(1){
width: 100%;

font-family: 'Rubik-Medium';
font-size: 16px;
line-height: 20px;
color:#0D4270;
padding: 15px 0px 10px 15px;

}
>div:nth-child(2){
  width: 100%;
  min-height: 76px;
  font-family: Rubik;
  font-size: 16px;
  line-height: 20px;
  color:#0D4270;
  padding:0px 35px 15px 15px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.3);
}
>div:nth-child(3){
  width: 100%;
  display: flex;
  margin-top: 15px;
  >div:nth-child(1){
    width:60%; 
    display: flex;
    padding-left: 15px;

    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color:#0D4270;
    margin: auto;    
  }
  >div:nth-child(2){
    width: 40%;
    display: flex;
    justify-content: flex-end;
    padding-right: 35px;

    >button{
      width: 70%;
      height: 40px;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 21px;
      color:#fff;
  
      display: flex;
      border: none;
      border-radius: 6px;
      background: ${({ background }) => background ? background : 'rgb(105, 194, 255)'};
      align-items: center;
      justify-content: center;
    }
  }
}

>div:nth-child(4){
  margin-top: 15px;
  width: 100%;
  background: rgba(156, 156, 156, 0.3);
  height: 24px;

  font-family: Rubik;
  font-size: 12px;
  line-height: 14px;
  color:#0D4270;
  display: flex;
  justify-content: center;
  align-items: center;
  }
`;

const ImageContainer = styled.div`
width: ${({ width }) => width ? width : '15%'};
min-width: 125px;
height: ${({ height }) => height ? height : '100px'};
float: left;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 5px 0px 0px 5px;
  }
`;
const TextContainer = styled.div`
width: ${({ width }) => width ? width : '80%'};
height: 100%;
padding: 15px;
float: right;
display: flex;
justify-content: space-evenly;
flex-flow: column;

  > span: first-child {
    text-transform: uppercase;
    font-size: 14px;
    color: #9ECDFF;
    font-family: ${RegularFont};
    color: ${({ color }) => color ? '#fff' : '#9ECDFF'};
  }
  > p {
    text-transform: uppercase;
    font-size: 15px;
    color: #FFF;
    font-family: ${MediumFont};
  }
  > div {
    width: 100%;
    display: flex;

    > span {
    width: 20%;
    font-size: 10px;
    color: #F6B479;
    font-family: ${RegularFont};
    padding-left: 5px;
    margin-top: -3px;
    }
  }
`;

const TextContainerV2 = styled.div`
width: ${({ width }) => width ? width : '80%'};
height: 100%;
float: right;
display: flex;
justify-content: space-evenly;
flex-flow: column;
>div:nth-child(1){
  width: 100%;
  display: flex;
  padding-top: 15px;
  >div:nth-child(1){
    display: flex;
    width: 50%;
    justify-content: flex-start;

    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 18px;
    color: #0D4270;
    padding-left 25px;
    margin: auto;
  }
  >div:nth-child(2){
    display: flex;
    width: 50%;
    justify-content: flex-end;
    padding-right: 15px;
    >div{
      width: 25px;
      height: 25px;
      div{
        >img{
        width: 100%;
        height: 100%;
      }

      }
      >img{
        width: 100%;
        height: 100%;
      }
    }
  }
}

>div:nth-child(2){
  width: 100%;
  display: flex;
  padding-top: 15px;

  >div:nth-child(1){
    display: flex;
    width: 50%;
    justify-content: flex-start;

    font-family: Rubik;
    font-size: 14px;
    line-height: 18px;
    color:#0D4270;
    padding-left: 25px;
  }
  >div:nth-child(2){
    display: flex;
    width: 50%;
    justify-content: flex-end;
  }
}

>div:nth-child(3){
  width: 100%;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(156, 156, 156, 0.3);
  >div:nth-child(1){
    display: flex;
    width: 50%;
    justify-content: flex-start;
    padding-left: 35px;
  }

    .challenge-user {
      float: left;
      text-transform: capitalize;
      margin-left: 0px;
      @media (max-width: 660px) {
        position: static;
        margin: 10px 15px;
      }
    }
    .user-info {
      display: flex;
      margin-left: 0;
      > h6 {
        color: #67676A;
        font-size: 15px
        font-family: ${MediumFont};
        margin-left: 5px;
        text-transform: none;
        margin-top: 0;
      }
      > span {
        margin-left: -12px;
        > img {
          width:30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid rgb(255, 255, 255);
        }
      }
      .user-list {
        color: #9C9C9C;
        font-size: 14px;
        font-family: Rubik;
        line-height: 18px;
        margin-left: 5px;
        text-transform: none;
        margin-top: 0;
        display: flex;
        justify-content: center;
        align-items: center
      }
      .circle {
        font-family: ${MediumFont}
        font-size: 11px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid rgb(255, 255, 255);
        text-align: center;
        background-color: #4798D9;
        background: linear-gradient(9deg, #006FBC -1.11%, #7F43FF 97.46%);
      }
    }

  >div:nth-child(2){
    display: flex;
    width: 50%;
    justify-content: flex-end;

    font-family: Rubik;
    font-size: 14px;
    line-height: 18px;
    color:#0D4270;
    padding-right: 15px;
    margin: auto;
  }
}

>div:nth-child(4){
  width: 100%;
  display: flex;
  padding-top: 20px;
  >div:nth-child(1){
    display: flex;
    width: 50%;
    justify-content: flex-start;

    font-family: Rubik;
    font-size: 16px;
    line-height: 19px;
    color:#0D4270;
    padding-left: 25px;
  }
  >div:nth-child(2){
    display: flex;
    width: 50%;
    justify-content: flex-end;

    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color: ${({ color }) => color ? color : '#0D4270'};
    padding-right: 15px;
  }
}



  }
`;

const ProgressBar = styled.div`
  width: 80%;
  height: 33%;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.17);
  overflow: hidden;
  .progrss {
    height: 8px;
    border-radius: 9px;
    background-color: #F6B479;
    width: ${({width}) => width && `${width}%`};
  }
`;
const DayToStart = styled.div`
font-size: 10px;
color: #0D4270;
font-family: ${RegularFont}
padding-top: 7px;
`;
const DateContainer = styled.div`
font-size: 12px;
color: #0D4270;
font-family: ${MediumFont}
padding-top: 7px;
`;
const IconContainer = styled.div`
display: flex;
margin-left: 0px;
padding-left: 5px;

  > span {
    margin-left: -12px;

    > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid rgb(255, 255, 255);
    }
  }

  .circle {
    font-family: ${MediumFont}
    font-size: 11px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    text-align: center;
    background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  }
`;
const IconRight = styled.div`
width: 5%;
display: flex;
padding-top: 45px;
>img{

  margin:0px;
  width:7px;
  height:16px;
}
`;

const ImageContainerNew = styled.div`
width: 15%;
min-width: 167px;
height: 104px;
float: left;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 5px 0px 0px 0px;
  }
`;

const SubContainerNew = styled.div`
width: 100%;
cursor: pointer;
background-color: #FFF;
margin-top: 15px;
border-radius: 6px;
margin: ${({ margin }) => margin ? margin : '15px 0 0 0'};

> div: first-child {
  padding: 5px;
  width: 100%;
  height: ${({ height }) => height ? height : '100px'};
  background: ${({ background }) => background ? 'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)' : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding:0px;
  border-radius: 6px 0px 0px 6px;


  padding: 7px;
  display: flex;
  justify-content: space-between;
  padding:0px;
  border-radius: 6px 6px 0px 0px;
}

> div: last-child {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  border-radius: 0px 0px 6px 6px;
  >div: last-child{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    margin: auto;
    margin-right: 15px;
    >span{
      font-family: 'Rubik-Regular';
      font-size: 14px;
      line-height: 18px;
      color:#0D4270;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  >div: first-child{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    margin-left: 15px;
    span{
      font-family: 'Rubik-Medium';
      font-size: 14px;
      line-height: 18px;
      color: #0D4270
    }
  }
}
`;

const TextContainerNew = styled.div`
width: 90%;
height: 100%;
padding: 15px;
float: right;
display: flex;
justify-content: space-evenly;
flex-flow: column;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
  > p {
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const MainContainerNew = styled.div`
float: left;
width: 100%;
height: 100%;
padding: 15px 25px 0px 0px;
padding: ${({ paddingValue }) => paddingValue ? paddingValue : '15px 25px 0px 0px'};
  > div: first-child {
    font-size: 18px;
    color: #0D4270;
    font-family: ${MediumFont};
    display: flex;
    justify-content: center;
    width: 100%;
    >div:nth-child(1){
      width: 90%
      display: flex;
      justify-content: flex-start;
      margin: auto;
      margin-left:0px;


      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 21px
      color: #0D4270;
      color: ${({ adminChallenge }) => adminChallenge ? "#005c87" : '#0D4270'};
    }
    >div:nth-child(2){
      width: 30%;
      display: flex;
      justify-content: flex-end;
      margin: auto;
      margin-right:0px;

      font-family: Rubik;
      font-size: 18px;
      line-height: 24px;
      color:#FD7175;
      cursor: pointer;
    }
  }
  @media(max-width: 1200px) {
		width: 100%;
		margin-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }

`;

const HeadingMain = styled.div`
width: 100%;
display: flex;
>div{
  width: 100%;
  display: flex;
>div:nth-child(1){
  width: 50%;
  text-transform: capitalize;
  font-size: 18px;
  color: #0D4270;
  font-family: ${MediumFont};
}
>div:nth-child(2){
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 0px;
  font-family: Rubik;
  font-size: 18px;
  line-height: 24px;
  color: #FD7175;
  cursor: pointer;
  >div{
    display: flex;
    justify-content: flex-end;
    margin: auto;
    margin-right: 0px;
    font-family: Rubik;
    font-size: 18px;
    line-height: 24px;
    color: #FD7175;
    cursor: pointer;
  }
}
}
`;


export {
  MainContainer, SubContainer, ImageContainer, TextContainer, ProgressBar, DayToStart, DateContainer, IconContainer, IconRight, ImageContainerNew, SubContainerNew, TextContainerNew, MainContainerNew, SubContainerV2, TextContainerV2, SubContainerBehavior,ContainerFirst, ContainerLast, HeadingMain
}